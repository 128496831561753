@import "../../../styles/variables.module";
.freeFeatures {
  & ~ .faq {
    padding-top: 80px !important;
    & ~ .recentArticles {
      padding-top: 80px;
    }
  }
}
.faq {
  .faqAccordion {
    .accordion {
      border: none;
      .accordion__item {
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: $white-color;
        clear: both;
        overflow: hidden;
        border: 1px solid transparent;
        @include large_device {
          margin-bottom: 10px;
        }
        &.show {
          border-color: $secondary-color;
          .accordionHeader {
            .accordionButton {
              &::after {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
              }
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        .accordionHeader {
          -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
          border-radius: 10px;
        }
        p {
          margin-bottom: 0;
          font-size: 16px;
          @include mobile_device {
            font-size: 14px;
            line-height: 24px;
          }
        }
        .accordionButton {
          background: $white-color;
          padding: 19px 16px;
          border-radius: $border-radius;
          -webkit-box-shadow: none;
          box-shadow: none;
          cursor: pointer;
          @include large_device {
            font-size: 16px;
            padding-right: 20px;
          }
          @include medium_device {
            padding: 15px;
          }
          .faqTitle {
            font-size: 18px;
            color: $theme-custom-color;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 0;
            @include large_device {
              font-size: 16px;
              padding-right: 10px;
            }
          }
          &[aria-expanded="true"] {
            -webkit-box-shadow: 0 4px 10px rgb(0, 0, 0, 0.03);
            box-shadow: 0 4px 10px rgb(0, 0, 0, 0.03);
            &::after {
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
          &::after {
            content: "";
            background-image: url("../../" + $bg-base-path + "/dropdown-toggle.svg");
            width: 15px;
            height: 8px;
            background-size: 15px;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            @include large_device {
              width: 12px;
              height: 12px;
              background-size: contain;
            }
          }
          img {
            margin-right: 30px;
            width: 60px;
            height: 60px;
            border-radius: $border-radius;
            @include large_device {
              margin-right: 10px;
              width: 40px;
              height: 40px;
            }
          }
        }
        .accordionBody {
          padding: 24px 16px;
          @include medium_device {
            padding: 10px;
          }
          ul {
            margin-top: 10px;
            padding-left: 20px;
            li {
              color: $desc-font;
              font-size: 16px;
              line-height: 28px;
              list-style: disc;
            }
          }
          .desc {
            margin-bottom: 0;
            width: 100%;
            font-size: 16px;
            line-height: 28px;
            @include large_device {
              font-size: 14px;
              line-height: 24px;
            }
            ul,
            ol {
              padding-left: 18px;
              margin-bottom: 0;
              li {
                list-style: decimal;
                padding-left: 5px;
              }
            }
          }
        }
      }
      &.row {
        .accordion__itemWrapper:first-child {
          @include medium_device {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  & ~ .recentArticles {
    padding-top: 0;
    .articlesCard {
      margin-top: 50px;
    }
    .categoryBrowser{
      margin-bottom: 0;
    }
  }
}
